// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        //console.log("HMR")
    });
}

import "flickity/css/flickity.css"
import Flickity from 'flickity'

import '../css/main.css'

import './controllers'

import 'lazysizes';

document.addEventListener("DOMContentLoaded", function(){

    const tham = document.querySelector(".burger");

    tham.addEventListener('click', () => {
        tham.querySelector(".tham").classList.toggle('tham-active');

        if(tham.querySelector(".tham").classList.contains("tham-active")) {
            lockpage()
        } else {
            unlockpage()
        }

    });

    function lockpage() {
        document.querySelector("html").classList.remove("scroll-smooth")
        document.querySelector("html").classList.add("locked")


        document.querySelector(".mobile-navigation").classList.remove("hidden")
        document.querySelector(".site-overlay").classList.remove("hidden")
        setTimeout(() => {
            document.querySelector(".site-overlay").classList.add("opacity-70")
            document.querySelector("body").classList.add("show-menu")
        },10)

    }

    function unlockpage() {


        document.querySelector("body").classList.remove("show-menu")


        setTimeout(() => {
            document.querySelector("html").classList.add("scroll-smooth")
            document.querySelector("html").classList.remove("locked")
            document.querySelector(".site-overlay").classList.remove("opacity-70")
            setTimeout(() => {
                document.querySelector(".site-overlay").classList.add("hidden")
            },10)
        }, 20)
    }
    
    // Lazysizes

    let lazys = document.querySelectorAll(".lazy-start");
    lazys.forEach(function(entry) {
        entry.classList.add("lazyload")
    });

    // Referenzen

    let referenzSlider = document.querySelectorAll(".slider-referenzen");
    referenzSlider.forEach(function(slider) {

        var flkty = new Flickity(  slider.querySelector(".slider"), {
            adaptiveHeight: false,
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            autoPlay: 3500,
            pauseAutoPlayOnHover: false,
            draggable: false,
            cellAlign: "left",
        });
        slider.querySelector(".slider-next").addEventListener('click', (event) => {
            var flkty = new Flickity(  event.target.closest(".slider-referenzen").querySelector(".slider"))
            flkty.stopPlayer()
            flkty.next()
        });
        slider.classList.add("initialized")
    });

    // Imagefader

    let imageFaders = document.querySelectorAll(".imagefader");
    imageFaders.forEach(function(imageFader) {

        setTimeout(() => {
            imageFader.classList.add("initialized")
        },100)

        var imageSlides = imageFader.querySelectorAll(".slide")
        var indicators = imageFader.querySelectorAll(".indicator")

        indicators.forEach(function(indicator) {
            indicator.addEventListener('click', (event) => {
                var currentIndex = indexInParent(imageSlides[0].parentNode.querySelector(".active"));
                var nextIndex = indexInParent(event.target)
                indicators[currentIndex].removeEventListener('transitionend', moveNext);
                nextSlide(currentIndex,nextIndex)
            });
        });

        imageSlides[0].classList.add("active")
        setTimeout(() => {
            nextSlide(0,0)
        }, 100)

        function nextSlide(currentIndex,nextIndex) {
            indicators[currentIndex].classList.remove("active")
            indicators[nextIndex].classList.add("active")

            imageSlides[currentIndex].classList.remove("active")
            imageSlides[nextIndex].classList.add("active")

            indicators[nextIndex].addEventListener('transitionend', moveNext);

        }

        function moveNext() {

            var currentIndex = indexInParent(imageSlides[0].parentNode.querySelector(".active"));
            var nextIndex = currentIndex+1;
            if(nextIndex>imageSlides.length-1) {
                nextIndex = 0;
            }

            nextSlide(currentIndex,nextIndex)

            indicators[currentIndex].removeEventListener('transitionend', moveNext);
        }



    });


    window.addEventListener('resize', function () {
        resize();
    });
    resize();


    function resize() {

    }

});


function indexInParent(node) {
    var children = node.parentNode.childNodes;
    var num = 0;
    for (var i=0; i<children.length; i++) {
        if (children[i]==node) return num;
        if (children[i].nodeType==1) num++;
    }
    return -1;
}